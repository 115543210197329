.heading {
  color: #000;
  font-family: inherit;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.4px;
}

.description {
  color: #000;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  flex-shrink: 0;
}

.amount {
  font-family: inherit;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.76px;
  background: var(
    --Linear,
    linear-gradient(91deg, #01a0aa 1.65%, #076b9e 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
body {
  margin: 0;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff !important;
}
::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #0487a5; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.btn-dropdown-halo {
  --bs-btn-color: #fff;
  --bs-btn-bg: #19875400 !important;
  --bs-btn-border-color: #fbfbfb00 !important;
  /* --bs-btn-hover-color: #fff; */
  --bs-btn-hover-bg: #19875400 !important;
  --bs-btn-hover-border-color: #19875400 !important;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #19875400 !important;
  --bs-btn-active-border-color: #19875400 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #19875400 !important;
  --bs-btn-disabled-border-color: #19875400 !important;
  --bs-btn-font-weight: 500 !important;
  width: auto;
}
.btn-dropdown-list-halo
{
  width: auto;
}

.logo-nav-flexbox-container{
  display:flex;
  align-items: flex-start;
}
.sub-booking-row {
  background-color: #f0f0f0; /* Light gray background */
}
/* global.css */
.MuiTelInput-root {
  border: none !important;
  outline: none !important;
}

.MuiInputBase-root {
  border: none !important;
  outline: none !important;
}

.MuiInputBase-root:hover {
  border: none !important;
  outline: none !important;
}

.MuiInputBase-root.Mui-focused {
  border: none !important;
  outline: none !important;
}
